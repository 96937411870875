<template>
  <div>
    <vx-card title="Trade Payment">
      <div class="flex flex-col gap-4">
        <operating-unit-filter
          @onShow="onShow"
          :hideShowButton="true"
          :multipleTerritory="true"
        />

        <div class="flex flex-col gap-3 sm:w-2/3 w-full whitespace-no-wrap">
          <customer-filter
            :multiple="true"
            :full="true"
            :showClearButton="true"
          />
        </div>

        <div class="flex flex-col gap-3 sm:w-2/3 w-full whitespace-no-wrap">
          <div class="flex flex-col justify-between gap-2">
            <span>Payment Method</span>
            <div class="flex justify-between">
              <multiselect
                v-model="selectedPaymentMethod"
                :options="paymentMethodOptions"
                :multiple="true"
                :max-height="160"
                :limit="3"
                placeholder="Type Payment Method"
                label="method"
                track-by="id"
              >
                <template slot="tag" slot-scope="props">
                  <div class="multiselect__tag">
                    <span>{{ props.option.method }}</span>
                    <i
                      class="multiselect__tag-icon"
                      @click.prevent
                      @mousedown.prevent.stop="
                        props.remove(props.option, $event)
                      "
                    />
                  </div>
                </template>
              </multiselect>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-3 sm:w-2/3 w-full whitespace-no-wrap">
          <div class="flex flex-col justify-between gap-2">
            <span>Invoice Number</span>
            <div class="flex justify-between">
              <multiselect
                v-model="invoiceCodeTags"
                :options="invoiceCodeOptions"
                :multiple="true"
                :max-height="160"
                :limit="3"
                placeholder="Type Invoice Code"
                label="code"
                :taggable="true"
                track-by="code"
                @search-change="onAddTag"
                @tag="onTagInput"
                ref="tagInput"
              >
                <template slot="tag" slot-scope="props">
                  <div class="multiselect__tag">
                    <span>{{ props.option.code }}</span>
                    <i
                      class="multiselect__tag-icon"
                      @click.prevent
                      @mousedown.prevent.stop="
                        props.remove(props.option, $event)
                      "
                    />
                  </div>
                </template>
              </multiselect>
              <vs-button
                class="ml-4"
                color="danger"
                icon-pack="feather"
                icon="icon-x"
                @click="resetTag"
              ></vs-button>
            </div>
          </div>
        </div>

        <div class="flex gap-3 sm:w-2/3 w-full justify-end mt-5 mb-5">
          <vs-button @click="onShow">Show</vs-button>
        </div>

        <vs-tabs :color="colorx" v-model="tabs">
          <vs-tab @click="colorx = 'danger'" label="Open">
            <div class="con-tab-ejemplo">
              <open
                :isReload="isReload"
                :invoiceCode="invoiceCodes"
                :paymentMethod="paymentMethodQuery"
                :currentTab="tabs"
              />
            </div>
          </vs-tab>
          <!-- <vs-tab @click="colorx = 'warning'" label="Apply">
            <div class="con-tab-ejemplo">
              <apply
                :isReload="isReload"
              />
            </div>
          </vs-tab> -->
          <vs-tab @click="colorx = 'success'" label="Waiting Reconcile">
            <div class="con-tab-ejemplo">
              <reconcile
                :isReload="isReload"
                :invoiceCode="invoiceCodes"
                :paymentMethod="paymentMethodQuery"
                :currentTab="tabs"
              />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'primary'" label="Done">
            <div class="con-tab-ejemplo">
              <done
                :isReload="isReload"
                :invoiceCode="invoiceCodes"
                :paymentMethod="paymentMethodQuery"
                :currentTab="tabs"
              />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'success'" label="Waiting Reverse">
            <div class="con-tab-ejemplo">
              <waiting-reverse
                :isReload="isReload"
                :invoiceCode="invoiceCodes"
                :paymentMethod="paymentMethodQuery"
              />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'success'" label="Reversed">
            <div class="con-tab-ejemplo">
              <reversed
                :isReload="isReload"
                :invoiceCode="invoiceCodes"
                :paymentMethod="paymentMethodQuery"
              />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>
<script>
import open from "./open.vue";
import apply from "./apply.vue";
import reconcile from "./reconcile.vue";
import done from "./done.vue";
import reversed from "./reversed.vue";
import waitingReverse from "./waiting_reverse.vue";
import operatingUnitFilter from "../../../../components/operatingUnitFilter/operatingUnitFilter.vue";
import customerFilterVue from "../../../../components/customerFilter/customerFilter.vue";
export default {
  components: {
    open,
    apply,
    reconcile,
    done,
    reversed,
    "operating-unit-filter": operatingUnitFilter,
    "waiting-reverse": waitingReverse,
    "customer-filter": customerFilterVue,
  },
  async mounted() {
    await this.$store.dispatch("user/getPermissions", "payment");
    this.getOptions();
  },
  data: () => ({
    colorx: "danger",
    tabs: 0,
    isReload: false,
    invoiceCodeTags: [],
    methodAllOption: {
      id: 0,
      method: "All",
    },
    invoiceCodeOptionAll: {
      id: 0,
      method: "All",
    },
    invoiceCodeOptions: [],
    paymentMethod: [
      {
        id: 0,
        method: "All",
      },
    ],

    paymentMethodOptions: [
      {
        id: 0,
        method: "All",
      },
      {
        id: 1,
        method: "Cash",
        query_text: ["Cash", "CASH", "Tunai", "COD (Cash On Delivery)"],
      },
      {
        id: 2,
        method: "Giro",
        query_text: ["Giro"],
      },
      {
        id: 3,
        method: "Credit Note",
        query_text: [
          "CN Claim",
          "CN Full Return",
          "CN Return",
          "CN Without Cashmemo",
          "Credit Note",
        ],
      },
      {
        id: 4,
        method: "Customer Deposit",
        query_text: ["Cust Deposit", "Customer Deposit"],
      },
      {
        id: 5,
        method: "Transfer",
        query_text: ["Transfer", "TRANSFER", "Bank Transfer"],
      },
      {
        id: 6,
        method: "Cheque",
        query_text: ["Cheque"],
      },
    ],
  }),
  created() {},
  computed: {
    invoiceCodes() {
      //   console.log(
      //     this.invoiceCodeTags.map((tag) => tag.code),
      //     "invoiceCodeTags"
      //   );
      return this.invoiceCodeTags.map((tag) => tag.code);
    },
    selectedPaymentMethod: {
      get() {
        return this.paymentMethod;
      },
      set(value) {
        if (value.length === 0) {
          this.paymentMethod = [this.methodAllOption];
          return;
        }
        if (value[0].id === 0) {
          value.shift();
          this.paymentMethod = value;
          // this.selectedOperatingUnit = value;
        } else if (value.some((mt) => mt.id === 0)) {
          // this.selectedOperatingUnit = [{ ID: 0, Code: "All", Name: "All" }];
          this.paymentMethod = [this.methodAllOption];
        } else {
          // this.selectedOperatingUnit = value;
          this.paymentMethod = value;
        }
      },
    },
    paymentMethodQuery() {
      if (this.paymentMethod.length === 1) {
        if (this.paymentMethod.some((method) => method.id === 0)) {
          return [];
        } else {
          return this.paymentMethod[0].query_text;
        }
      } else {
        const methods = [];
        this.paymentMethod.forEach((method) => {
          methods.push(...method.query_text);
        });
        return methods;
      }
    },
  },
  methods: {
    onShow() {
      this.isReload = true;
      this.$nextTick(() => {
        this.isReload = false;
      });
    },
    onTagInput(tag) {
      console.log("here is the tag");
      const tagObject = {
        id: this.invoiceCodeTags.length + 1,
        code: tag,
      };
      this.invoiceCodeTags.push(tagObject);
    },
    async onAddTag(tag) {
      console.log(tag);
      const tags = tag.replace(/\s+/g, "").trim().split(";");

      if (tag.includes(";")) {
        console.log("contain ;");
        console.log(tags.length);
        for (let i = 0; i < tags.length; i++) {
          const tagObject = {
            id: this.invoiceCodeTags.length + 1,
            code: tags[i],
          };
          this.invoiceCodeTags.push(tagObject);
        }
        // this.invoiceCodeTags.pop();
        this.$refs.tagInput.deactivate();
      } else {
        if (tag.length < 3 && tag.length > 0) {
          return;
        }

        this.$vs.loading();
        const resp = await this.getInvoiceFilter(tag);
        if (resp.code === 200) {
          this.invoiceCodeOptions = resp.data;
        } else {
          this.invoiceCodeOptions = [];
        }
        this.$vs.loading.close();
      }
    },
    async getOptions() {
      try {
        this.$vs.loading();

        const resp = await this.getInvoiceFilter();
        if (resp.code === 200) {
          this.invoiceCodeOptions = resp.data;
        } else {
          this.invoiceCodeOptions = [];
        }

        console.log(this.customerOptions);

        // this.isQueryDone = true;
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
      }
    },
    getInvoiceFilter(search = "") {
      return this.$http.get("/api/v1/invoice/exchange/filter", {
        params: {
          search,
          length: 50,
          page: 1,
          order: "desc",
          sort: "id",
          status_not_in: [7, 4],
          //   territory_ids: this.territoryIDs,
          //   customer_name:
          //     !this.selectedCustomer.name || this.selectedCustomer.name == "All"
          //       ? undefined
          //       : this.selectedCustomer.name,
          //   customer_code:
          //     !this.selectedCustomer.code || this.selectedCustomer.code == "All"
          //       ? undefined
          //       : this.selectedCustomer.code,
          //   start_date: !this.selectedDate.startDate
          //     ? undefined
          //     : this.dateFormatApi(this.selectedDate.startDate),
          //   end_date: !this.selectedDate.endDate
          //     ? undefined
          //     : this.dateFormatApi(this.selectedDate.endDate),
        },
      });
    },
    resetTag() {
      this.invoiceCodeTags = [];
    },
  },
  watch: {},
};
</script>
